
import { defineComponent } from 'vue';
import BaseError from '@/components/ui/BaseError.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import barcodes from '@/services/barcodes';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'UploadBarcodes',
  components: { BaseError, SearchDropdown, SmallSpinner },
  data: () => ({
    isLoading: false,
    requestData: {
      files: null,
    } as any
  }),
  computed: {
    isDisabled(): boolean {
      const requiredValues = [
        this.requestData.files,
      ]

      return requiredValues.some((value: any) => !value)
    },
    getTemplate() {
      return barcodes.getTemplate()
    },
  },
  methods: {
    async downloadTemplate() {
      const table = await barcodes.getTemplate() as any
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `template.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async uploadBarcodes() {
      this.isLoading = true

      try {
        const formData = new FormData();
        formData.append('file', this.requestData.files[0])
        await barcodes.uploadBarcodes(formData)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      } catch (e) {
        console.error(e)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      } finally {
        this.isLoading = false
        this.setDefault()
      }
    },
    setFile(event: any) {
      this.requestData.files = event.target.files || event.dataTransfer.files;
    },
    setDefault() {
      const inputRef = this.$refs.fileInput as InstanceType<typeof HTMLInputElement>
      inputRef.value = ''

      Object.keys(this.requestData).map((key: string) => {
        this.requestData[key] = null
      })
    },
  },
})
