
import { defineComponent } from 'vue';
import certificates from '@/services/certificates';
import BaseError from '@/components/ui/BaseError.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import { ErrorFile } from '@/models/CertificateModel';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import barcodes from '@/services/barcodes';

export default defineComponent({
  name: 'UploadRequestForm',
  components: { BaseError, SearchDropdown },
  props: {
    getRequests: {
      type: Function,
      default: () => {}
    },
    certificateType: String
  },
  data: () => ({
    requestData: {
      files: null,
      brand: null,
      productGroup: null,
      productSubGroup: null
    } as any
  }),
  computed: {
    isDisabled(): boolean {
      const requiredValues = [
        this.requestData.files,
        // this.requestData.brand,
        // this.requestData.productGroup,
        // this.requestData.productSubGroup,
      ]

      return requiredValues.some((value: any) => !value)
    },
    uploadFileErrors(): Array<ErrorFile> {
      return this.$store.getters.getUploadFileErrors
    }
  },
  methods: {
    async downloadTemplate() {
      const table = await certificates.requestsUploadTemplate() as any
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `template.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    uploadRequests() {
      this.$store.dispatch('requestsFileUpload', {
        ...this.requestData,
        type: this.certificateType
      }).then(() => {
        if (!this.uploadFileErrors.length) {
          this.getRequests({ pageNumber: 0 })
          this.$emit('closeModal')
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        } else {
          this.setDefault()
        }
      })
    },
    setFile(event: any) {
      this.requestData.files = event.target.files || event.dataTransfer.files;
    },
    setDefault() {
      const inputRef = this.$refs.fileInput as InstanceType<typeof HTMLInputElement>
      inputRef.value = ''

      Object.keys(this.requestData).map((key: string) => {
        this.requestData[key] = null
      })
    },
  },
})
