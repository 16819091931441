
import { defineComponent } from 'vue';
import barcodes from '@/services/barcodes';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import SmallSpinner from '@/components/SmallSpinner.vue';
import cross from '@/services/cross';
import timeService from '@/services/timeService';

export default defineComponent({
  name: 'UploadCrosses',
  components: { SmallSpinner },
  data: () => ({
    isLoading: false,
    requestData: {
      files: null,
    } as any,
    blobDataErrors: null as any
  }),
  computed: {
    isDisabled(): boolean {
      const requiredValues = [
        this.requestData.files,
      ]

      return requiredValues.some((value: any) => !value)
    },
  },
  methods: {
    downloadReportFileErrors() {
      let date = timeService.createDateForViewFromUTCTime();
      const blob = new Blob([this.blobDataErrors], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `Price_report_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.blobDataErrors = null
    },
    async downloadTemplate() {
      const table = await cross.getTemplate() as any
      const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `cross-uploading-template.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async uploadCrosses() {
      this.isLoading = true

      try {
        const formData = new FormData();
        formData.append('file', this.requestData.files[0])
        await cross.uploadCrossesFromFile(formData)
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      } catch (error) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
        this.blobDataErrors = error.data
      } finally {
        this.isLoading = false
        this.setDefault()
      }
    },
    setFile(event: any) {
      this.requestData.files = event.target.files || event.dataTransfer.files;
    },
    setDefault() {
      const inputRef = this.$refs.fileInput as InstanceType<typeof HTMLInputElement>
      inputRef.value = ''

      Object.keys(this.requestData).map((key: string) => {
        this.requestData[key] = null
      })
    },
  },
})
