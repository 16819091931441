
import { defineComponent } from 'vue';
import UploadRequestForm
  from '@/components/certificates/upload-and-add-request/UploadRequestForm.vue';
import UploadLogisticParams from '@/components/upload/UploadLogisticParams.vue';
import UploadBarcodes from '@/components/upload/UploadBarcodes.vue';
import UploadCrosses from '@/components/upload/UploadCrosses.vue';

export default defineComponent({
  name: 'Upload',
  components: {
    UploadCrosses,
    UploadBarcodes,
    UploadLogisticParams,
    UploadRequestForm
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
  },
})
